import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

const LoginPage = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`会員向けログイン | ${title}`}</title>
        <meta name="description" content="各種ログインページのご案内です。" />
      </Helmet>
      <div className="common-header clearfix login-for-member">
        <h1>会員向けログイン</h1>
        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /<li>会員向けログイン</li>
        </div>
      </div>
      <div className="main-wrapper login-for-member">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>運営会社様はこちら</h2>
          </div>
        </div>

        <div className="row">
          <div className="container contact company">
            <div className="box">
              <a
                href="https://www.itcbb.net/gns/McpLogin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="title">駐車場運営会社様</p>
                <p className="detail">
                  QT-netやｄポイントなど、月次実績や駐車場ごとの実績がご確認いただけます。
                </p>
                <div className="link-box">ログインページ</div>
                <div className="shadow sp-only"></div>
                <div className="blanc sp-only"></div>
              </a>
            </div>

            <div className="shadow pc-only"></div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>QT-net 会員様はこちら</h2>
          </div>
        </div>

        <div className="row">
          <div className="container contact">
            <div className="box left">
              <a
                href="https://qt-net.itcbb.net/signin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="title">個人会員様</p>
                <p className="detail">
                  会員情報の照会や変更、ポイント交換申請、
                  <br />
                  SmartPay領収書発行はこちら。
                </p>
                <div className="link-box">QT-net会員ログイン</div>
                <div className="shadow sp-only"></div>
              </a>
            </div>

            <div className="box right">
              <a
                href="https://biz-adm.itcbb.net/internal/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="title">法人会員様</p>
                <p className="detail">
                  利用者登録、利用額照会などが
                  <br />
                  ご確認いただけます。
                </p>
                <div className="link-box">QT-net法人会員ログイン</div>
                <div className="shadow sp-only"></div>
              </a>
            </div>

            <div className="shadow pc-only"></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LoginPage
